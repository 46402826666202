

/* header */

.appBarStyle {
    opacity: 0.95;    
    background-color: white;
}

.logo {
    font-weight: 700;
    font-size: x-large;
    .img-logo {
        height: 100px;
    }
  }
  
.nav-links{
    display: flex!important;
    gap: 20px;
}

/* main */
.main-container {
    /* margin: 0 20px 0 20px; */
}
.section-container {
    display: flex;
    flex-grow: 1;
    gap: 100px;
    padding-top: 100px;    
}

.cell-1 {
    max-height: 300px;
    border-radius: 15px;
    justify-content: center;
}

.cell-2 {
}

.align-center-1 {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 860px) {
    .section-container{
        flex-direction: column;
        gap: 20px;
    }
    .cell-1{
        width: 100%;
    }
    .even-1{
        order: 0;
    }
    .even-2{
        order: 1;
    }
    .footer {
        gap: 10px !important;
        flex-direction: column;    
    }
    .align-center-1 {
        display: inline-block;
    }
}


/* footer */

.footer-container {
    margin-top: 70px;
    overflow: auto;
    background-color: #ccd1d4;
}

.footer{
    display: flex;
    gap: 70px;
    margin-left: 10px;
}

.contact {
    text-decoration: none;
}

.carousel-caption {
    bottom: unset !important;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.8vw;
}

.padding10 {
    margin: 5px 5px 10px 0 !important;
}

.padding5 {
    margin: 0 5px 5px 0 !important;
}